import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

const FIELDS_TO_QUERY = [
    'nog',
    'roomTypes',
    'mealTypes',
    'priceType',
];

export interface ClusterExcelRequest {
    monthrange: string[];
    columns: { name: string, value: boolean }[];
    provider: string;
    query: string;
}

export class ClusterExcelRequestModel implements ClusterExcelRequest {
    @Expose({ name: 'monthrange' })
    @Transform(value => [
        moment(value).format('YYYY-MM-DD'),
        moment(new Date(value.getFullYear(), value.getMonth() + 1, 0)).format('YYYY-MM-DD'),
    ])
    monthrange!: string[];

    @Expose({ name: 'columns' })
    @Transform((value: Record<string, boolean>) => Object
        .entries(value)
        .map(([key, value]) => ({
            name: key,
            value,
        })))
    columns!: { name: string, value: boolean }[];

    @Expose()
    provider!: string;

    @Expose()
    @Transform((_, plain) => FIELDS_TO_QUERY.filter(field => Boolean(plain[field])).map(field => `${field}=${plain[field]}`).join('&'))
    query: string = '';
}
