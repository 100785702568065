
import { plainToClass } from 'class-transformer';
import { Component, Mixins } from 'vue-property-decorator';
import type { AxiosError } from 'axios';

import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import type ProvidersService from '@/modules/providers/providers.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { DATA_TYPE } from '@/modules/reports/constants';
import { $enum } from 'ts-enum-util';
import type RoomTypeModel from '@/modules/room-types/models/room-type.model';
import type { Item } from '@/modules/common/interfaces';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

import { ClusterExcelRequestModel } from '../models/cluter-excel-request.model';
import type { RatesClusterExcelForm } from '../interfaces/rates-cluster-excel-form.interface';
import type ClusterRatesService from '../cluster-rates.service';
import type ClusterService from '../cluster.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(DownloadReportControlMixin) {
    @inject(KEY.ClusterRatesService) private clusterRatesService!: ClusterRatesService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    form: RatesClusterExcelForm = {} as RatesClusterExcelForm;
    private isLoading = false;

    get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: DATA_TYPE.RATES,
        };
    }

    private get isDownloadDisabled() {
        const { provider, monthrange } = this.form;

        if (!monthrange || !provider) {
            return true;
        }

        return false;
    }

    private get buttons() {
        const buttons = [
            {
                label: this.$tc('popup.download'),
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
            {
                label: this.$tc('popup.send'),
                onClick: () => this.downloadExcel(true),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        return buttons;
    }

    private get properties() {
        const { month, year } = this.documentFiltersService;
        return [
            {
                label: this.$tc('popup.dateRange'),
                key: 'monthrange',
                component: MonthPicker,
                default: new Date(year, month),
                props: {
                    locale: this.$i18n.locale,
                },
            },
        ];
    }

    private get customColumns() {
        return [
            {
                key: 'change',
                label: this.$tc('titles.change'),
                default: true,
            },
            {
                key: 'score',
                label: this.$tc('titles.score'),
                default: true,
            },
            {
                key: 'diff_delta',
                label: this.$tc('titles.diffnum'),
                default: true,
            },
            {
                key: 'diff_precent',
                label: this.$tc('titles.diffper'),
                default: true,
            },
        ];
    }

    private get filters() {
        const { rateProviders } = this.providersService.userProviders;
        const providerItems = this.providersService.toItemsList(rateProviders);

        const filters = [
            {
                label: this.$tc('titles.provider'),
                key: 'provider',
                options: providerItems,
                default: this.clusterService.currentRatesProvider,
            },

            this.roomTypeFilter,
            this.mealTypeFilter,
            this.priceTypeFilter,
            this.occupancyFilter,
        ];

        return filters;
    }

    private get isShortFilter() {
        const { provider } = this.form;
        return provider ? provider.includes('google') : false;
    }

    private get roomTypeFilter() {
        const { roomTypeId } = this.clusterRatesService.settings;
        const items = this.roomTypesService.roomsWithAny
            .map((roomType: RoomTypeModel) => ({
                value: roomType.name,
                name: this.$te(roomType.displayName)
                    ? this.$tc(roomType.displayName)
                    : roomType.displayName,
            }));

        const defaultValue = roomTypeId === -1
            ? ANY_ROOM_TYPE.name
            : this.roomTypesService.getRoomType(roomTypeId)!.name;

        return {
            label: this.$tc('titles.roomType'),
            key: 'roomTypes',
            options: items,
            default: defaultValue,
            disabled: this.isShortFilter,
        };
    }

    private get priceTypeFilter() {
        const { priceType } = this.clusterRatesService.settings;

        const options = $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`price.${value}`) as string,
        }));

        const defaultPriceType = options
            .find(p => p.value === priceType) || options[0];

        return {
            label: this.$tc('titles.price'),
            key: 'priceType',
            options,
            default: defaultPriceType.value,
            disabled: this.isShortFilter,
        };
    }

    private get numberOfGuestsItems(): Item[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
            value: item,
            name: this.$tc('filters.guests', item, [item]),
        }));
    }

    private get occupancyFilter() {
        const { numberOfGuests } = this.clusterRatesService.settings;

        return {
            label: this.$tc('titles.numberOfGuests'),
            key: 'nog',
            options: this.numberOfGuestsItems,
            default: numberOfGuests || this.numberOfGuestsItems[0].value,
            disabled: this.isShortFilter,
        };
    }

    private get mealTypeFilter() {
        const { mealTypeId } = this.clusterRatesService.settings;
        const mealTypeItems = this.mealTypesService.mealTypes
            .map(item => ({
                id: item.id,
                name: this.$tc(item.displayName),
                value: item.name,
            }));

        const defaultValue = mealTypeId === -1
            ? ANY_MEAL_TYPE.name
            : this.mealTypesService.getMealType(mealTypeId)!.name;

        return {
            label: this.$tc('titles.mealType'),
            key: 'mealTypes',
            options: mealTypeItems,
            default: defaultValue,
            disabled: this.isShortFilter,
        };
    }

    private async downloadExcel(toEmail = false) {
        try {
            this.isLoading = true;
            const body = plainToClass(ClusterExcelRequestModel, this.form, { excludeExtraneousValues: true });

            await this.clusterRatesService.downloadExcel(body, toEmail);

            if (toEmail) {
                this.triggerFormMessage(this.$tc('popup.reportSent'));
            } else {
                this.closeForm();
            }
        } catch (e: unknown) {
            switch ((e as AxiosError).response?.status) {
                case 404:
                    this.triggerFormError(this.$tc('err.nodata'));
                    break;
                default:
                    this.triggerFormError(this.$tc('err.unknown'));
            }
        } finally {
            this.isLoading = false;
        }
    }
}
